import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingPopup from "./LoadingPopup";
import "./css/Donation.css";

const Donation = () => {
  const [selectedPlanter, setSelectedPlanter] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [numberOfTrees, setNumberOfTrees] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const [loadingPopupVisible, setLoadingPopupVisible] = useState(false);
  const [popupIcon, setPopupIcon] = useState("loading");
  const [popupText, setPopupText] = useState("");

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const planter = queryParams.get("planter");
    if (planter) {
      setSelectedPlanter(planter);
    }
  }, [location]);

  const handlePlanterSelect = (planter) => {
    setSelectedPlanter(planter);
  };

  const handleStateSelect = (state) => {
    setSelectedState(state);
  };

  const handleNumberOfTreesChange = (event) => {
    setNumberOfTrees(event.target.value);
  };

  const addNumberToTrees = (number) => {
    setNumberOfTrees((prev) => (prev ? parseInt(prev) + number : number));
  };

  useEffect(() => {
    const amount = numberOfTrees * 150;
    setTotalAmount(amount);
  }, [numberOfTrees]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(
        `https://greeningthered.greentheblue.org/Backend/payment/index.php?amount=${totalAmount}`
      );
      const data = await response.json();

      const options = {
        key: data.key,
        amount: data.amount,
        currency: data.currency,
        name: "Green the blue",
        description: "Donation Payment",
        order_id: data.order_id,
        handler: async function (response) {
          setPopupIcon("loading");
          setPopupText("Processing your donation...");
          setLoadingPopupVisible(true);

          const now = new Date();
          const donationId = data.donation_id;

          const donationData = {
            donationId: donationId,
            name: event.target.name.value,
            email: event.target.email.value,
            phoneNumber: event.target.phone.value,
            donorPlanter: selectedPlanter || "Farmer",
            donorState: selectedState || "Gujarat",
            numberOfTrees: numberOfTrees,
            amount: totalAmount,
            transactionStatus: "Success",
            transactionId: response.razorpay_payment_id,
            date: now.toLocaleDateString(),
            time: now.toLocaleTimeString(),
          };
          
          try {
            const response = await fetch(
              "https://greeningthered.greentheblue.org/Backend/saveDonation.php",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(donationData),
              }
            );

            const data = await response.json();

            if (data.status === "success") {
              // Send data to newdonardata.php
              const newDonorData = {
                donationId: donationData.donationId,
                name: donationData.name,
                email: donationData.email,
                phoneNumber: donationData.phoneNumber,
                donorPlanter: donationData.donorPlanter,
                donorState: donationData.donorState,
                numberOfTrees: donationData.numberOfTrees,
              };

              console.log("Sending new donor data:", newDonorData); // Debugging line

              await fetch(
                "https://greeningthered.greentheblue.org/Backend/newdonardata.php",
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(newDonorData),
                }
              );

              setPopupIcon("success");
              setPopupText("Donation successful!");
              setTimeout(() => {
                setLoadingPopupVisible(false);
                navigate("/thankyou");
              }, 2000);
            } else {
              alert(
                `Payment successful but failed to save data. Error: ${data.message}`
              );
              setLoadingPopupVisible(false);
            }
          } catch (error) {
            console.error("Error saving data:", error);
            alert(
              `Payment successful but failed to save data. Error: ${error.message}`
            );
            setLoadingPopupVisible(false);
          }
        },
        prefill: {
          name: event.target.name.value,
          email: event.target.email.value,
          contact: event.target.phone.value,
        },
        notes: {
          planter: selectedPlanter,
          state: selectedState,
        },
        theme: {
          color: "#3399cc",
        },
      };

      const rzp1 = new window.Razorpay(options);
      rzp1.open();
    } catch (error) {
      console.error("Error fetching order details:", error);
    }
  };

  return (
    <div className="donation-container">
      <LoadingPopup
        isVisible={loadingPopupVisible}
        icon={popupIcon}
        text={popupText}
      />
      <div className="donation-box">
        <h2>Make a Donation</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input type="text" id="name" name="name" required />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Phone Number</label>
            <input type="text" id="phone" name="phone" required />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input type="email" id="email" name="email" required />
          </div>
          <div className="form-group">
            <label>Select Planter</label>
            <div className="selection-boxes">
              <div
                className={`selection-box ${
                  selectedPlanter === "Farmers" ? "selected" : ""
                }`}
                onClick={() => handlePlanterSelect("Farmers")}
              >
                <img src="img/farmer.png" alt="Farmers" />
                <p>Farmers</p>
              </div>
              <div
                className={`selection-box ${
                  selectedPlanter === "Village Community" ? "selected" : ""
                }`}
                onClick={() => handlePlanterSelect("Village Community")}
              >
                <img src="img/village.png" alt="Village Community" />
                <p>Village Community</p>
              </div>
              <div
                className={`selection-box ${
                  selectedPlanter === "School" ? "selected" : ""
                }`}
                onClick={() => handlePlanterSelect("School")}
              >
                <img src="img/school.png" alt="School" />
                <p>School</p>
              </div>
            </div>
          </div>
          <div className="form-group">
            <label>Select State</label>
            <div className="selection-boxes">
              <div
                className={`selection-box ${
                  selectedState === "Gujarat" ? "selected" : ""
                }`}
                onClick={() => handleStateSelect("Gujarat")}
              >
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3e/Gujarat-stub.svg/1024px-Gujarat-stub.svg.png"
                  alt="Gujarat"
                />
                <p>Gujarat</p>
              </div>
              <div
                className={`selection-box ${
                  selectedState === "Tamil Nadu" ? "selected" : ""
                }`}
                onClick={() => handleStateSelect("Tamil Nadu")}
              >
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/12/Tamil_Nadu_map_for_WLM-IN.svg/800px-Tamil_Nadu_map_for_WLM-IN.svg.png"
                  alt="Tamil Nadu"
                />
                <p>Tamil Nadu</p>
              </div>
              <div
                className={`selection-box ${
                  selectedState === "Andhra Pradesh" ? "selected" : ""
                }`}
                onClick={() => handleStateSelect("Andhra Pradesh")}
              >
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/f/fe/Andhra_Pradesh_map_for_WLM-IN.svg"
                  alt="Andhra Pradesh"
                />
                <p>Andhra Pradesh</p>
              </div>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="numberOfTrees">Number of Trees</label>
            <input
              type="number"
              id="numberOfTrees"
              name="numberOfTrees"
              value={numberOfTrees}
              onChange={handleNumberOfTreesChange}
              min="1"
              required
              placeholder=" "
            />
          </div>
          <div className="number-boxes">
            {[10, 50, 100, 500, 1000].map((num) => (
              <div
                key={num}
                className="number-box"
                onClick={() => addNumberToTrees(num)}
              >
                <p>{num}</p>
              </div>
            ))}
          </div>
          <div className="total-amount-box">
            <p>Total Amount: ₹{totalAmount}</p>
          </div>
          <button type="submit">Submit Donation</button>
        </form>
      </div>
    </div>
  );
};

export default Donation;
