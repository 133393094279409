import React, { useEffect, useState } from 'react';
import './css/Donar.css';

const Donar = () => {
  const [donors, setDonors] = useState([]);

  useEffect(() => {
    fetch('https://greeningthered.greentheblue.org/Backend/fetch_donors.php')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        const sortedData = data.sort((a, b) => b.numberOfTrees - a.numberOfTrees);
        setDonors(sortedData);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  return (
    <div className="donor-container">
      <h1>Donor List</h1>
      <div className="donor-list">
        {donors.map((donor, index) => (
          <div key={index} className="donor-box">
            <h2>{donor.name}</h2>
            <p className="donor-id">Unique id: {donor.donationId}</p>
            <p className="donor-trees">Donated {donor.numberOfTrees} Trees</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Donar;
