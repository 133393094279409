import React from 'react';
import './css/TeamMemberCard.css';

const TeamMemberCard = ({ photo, name, intro, onLearnMoreClick }) => {
  return (
    <div className="team-member-card">
      <img src={photo} alt={`${name}'s photo`} className="team-member-photo" />
      <h3 className="team-member-name">{name}</h3>
      <p className="team-member-intro">{intro}</p>
      <button className="team-member-button" onClick={onLearnMoreClick}>Learn More</button>
    </div>
  );
};

export default TeamMemberCard;