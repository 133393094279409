// components/ImageGalleryGujarat.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import withFullscreen from './withFullscreen';
import "./css/ImageGallery.css";


const ImageGalleryGujarat = () => {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get('https://greeningthered.greentheblue.org/Backend/images_gujarat.php');
        if (response.data.status === 'success') {
          setImages(response.data.images);
        } else {
          setError('Failed to fetch images');
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchImages();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className='imagedisplay_container'>
      <h1>Image Gallery Gujarat</h1>
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
        {images.map((image, index) => {
          const FullscreenImage = withFullscreen((props) => <img {...props} />);

          return (
            <FullscreenImage
            className='image'
              key={index}
              src={`${image.image_path}`} // Adjust the path if needed
              alt={`Image ${index}`}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ImageGalleryGujarat;
