import React from 'react';
import './css/LoadingPopup.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const LoadingPopup = ({ isVisible, icon, text }) => {
  if (!isVisible) return null;

  const iconMapping = {
    loading: faSpinner,
    success: faCheckCircle,
  };

  return (
    <div className="loading-popup-overlay">
      <div className="loading-popup-content">
        <FontAwesomeIcon
          icon={iconMapping[icon]}
          className={icon === 'loading' ? 'rotating loading-popup-icon' : 'loading-popup-icon'}
        />
        <p className="loading-popup-text">{text}</p>
      </div>
    </div>
  );
};

export default LoadingPopup;

