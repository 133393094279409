import React, { useState, useEffect } from "react";
import { Route, Routes, Link } from "react-router-dom";
import Home from "./components/Home";
import About from "./components/About";
import Stories from "./components/Stories";
import Donar from "./components/Donar";
import Farmers from "./components/Farmers";
import School from "./components/School";
import Donation from "./components/Donation";
import ThankYou from "./components/ThankYou";
import NotFound from "./components/NotFound";
import NoInternet from "./components/NoInternet";
import ImageGalleryGujarat from "./components/ImageGalleryGujarat";
import ImageGalleryTamilNadu from "./components/ImageGalleryTamilNadu";
import ImageGalleryAndraPradesh from "./components/ImageGalleryAndraPradesh";
import "./App.css";
import logo from "./logo.png";

const App = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  if (!isOnline) {
    return <NoInternet />;
  }

  return (
    <div className="App">
      <header>
        <img src={logo} alt="Logo" />
        <nav>
          <ul className={menuOpen ? "menu open" : "menu"}>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li className="dropdown">
              <span className="dropbtn">
                About Project <i className="fas fa-caret-down"></i>
              </span>
              <div className="dropdown-content">
                <Link to="/about">About Project</Link>
                <Link to="/stories">Stories</Link>
                <Link to="/donar">Donar</Link>
                <div className="dropdown-submenu">
                  <span>
                    Planter <i className="fas fa-caret-right"></i>
                  </span>
                  <div className="dropdown-submenu-content">
                    <Link to="/farmers">Farmers</Link>
                    <Link to="/school">School</Link>
                    <Link to="/village-community">Village Community</Link>
                  </div>
                </div>
              </div>
            </li>
            <li className="dropdown">
              <span className="dropbtn">
                Gallery <i className="fas fa-caret-down"></i>
              </span>
              <div className="dropdown-content">
                <Link to="/image-gallery-gujarat">Gujarat</Link>
                <Link to="/image-gallery-tamil-nadu">Tamil Nadu</Link>
                <Link to="image-gallery-andra-pradesh">Andra Pradesh</Link>
              </div>
            </li>
            <li className="dropdown">
              <span className="dropbtn">
                Who We Are? <i className="fas fa-caret-down"></i>
              </span>
              <div className="dropdown-content">
                <a
                  href="https://greentheblue.org/about/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  About Us
                </a>
                <a
                  href="https://greentheblue.org/contact/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Contact Us
                </a>
              </div>
            </li>
          </ul>
        </nav>
        <div className="hamburger" onClick={toggleMenu}>
            <i className="fas fa-bars"></i>
          </div>
      </header>
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/about" element={<About />} />
          <Route path="/stories" element={<Stories />} />
          <Route path="/donar" element={<Donar />} />
          <Route path="/farmers" element={<Farmers />} />
          <Route path="/donation" element={<Donation />} />
          <Route path="/thankyou" element={<ThankYou />} />
          <Route
            path="/image-gallery-gujarat"
            element={<ImageGalleryGujarat />}
          />
          <Route
            path="/image-gallery-tamil-nadu"
            element={<ImageGalleryTamilNadu />}
          />
          <Route
            path="/image-gallery-andra-pradesh"
            element={<ImageGalleryAndraPradesh />}
          />
          <Route path="/school" element={<School />} />
          {/* Add additional routes here */}
        </Routes>
      </main>
    </div>
  );
};

export default App;
