import React from 'react';

const Stories = () => {
  return (
    <div style={{ marginTop: '50px' }}>
      <h1>Stories</h1>
      <p>No Stories Available</p>
    </div>
  );
};

export default Stories;