import React, { useEffect, useState } from "react";
import axios from "axios";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import { useNavigate } from "react-router-dom";
import ReactPlayer from 'react-player';
import "./css/Home.css";
import TeamMemberCard from "./TeamMemberCard";
import Popup from "./Popup";

const teamMembers = [
  {
    photo: "img/Jothi.jpg",
    name: "Jothi Xavier",
    intro: "Director of Project Management",
    details:
      "Jothi Xavier, Founder/Director of Green the Blue Charitable Trust, specializes in art, ecology, and innovative education. With research fellowships on Warli art and extensive experience in curating exhibitions on environmental issues, he leads initiatives in environmental education and youth empowerment programs. He holds a Master’s degree in Art History from M S University, Vadodara",
  },
  {
    photo: "img/Archana.jpg",
    name: "Archana M",
    intro: "Regional Coordinator for Gujarat",
    details: "Archana Macwana taught English for 15 years in Schools & Community College and joined Green the Blue Trust (GBT) full-time in 2017. She is a youth trainer and environmental educator known for her effective communication skills and hands-on teaching approach. As a trustee of GBT, Archana also coordinates the Qissa Pitara children's libraries, which are supported by the JCB Literature Foundation.",
  },
  {
    photo: "img/Lalitaben.jpg",
    name: "Lalitaben Vasava",
    intro: "Networking with Farmers",
    details: "Lalitaben Vasava has been associated with us since 2021 when we launched community learning centers to bridge the learning gaps during Covid. Since then, she has continued her association with Green the Blue Trust (GBT) and currently serves as the library resource person at Ashramshala, Zankhvav. In addition to her work in education, Seetaben also collaborates with farmers to promote natural farming practices.",
  },
  {
    photo: "img/Nikunj.jpeg",
    name: "Nikunj Makwana",
    intro: "Logistics",
    details: "Nikunj Makwana has been involved in the logistical supervision of Qissa Pitara libraries run by Green the Blue Trust (GBT). He has also participated in tree plantation drives at schools where GBT conducts its environmental education programs. With a decade of experience in logistical management for activity-based eco-education programs, Nikunj plays a vital role in ensuring the smooth execution of these initiatives.",
  },
  {
    photo: "img/Priyanka.jpg",
    name: "Priyanka Chaudhary",
    intro: "Documentation  & School Engagement",
    details: "Priyanka Chaudhary has been actively engaged at the grassroots level, working with children and youth on various projects, including innovative education programs, environment awareness initiatives, and youth animation. As a young farmer, she plays a crucial role in coordinating Green the Blue Trust's (GBT) programs at the grassroots level. Priyanka Chaudhary coordinates Qissa Pitara Children’s libraries in South Gujarat. She is also library Resource person at Ummarkadi village at Umarpada village in Surat Dt, Gujarat.",
  },
  {
    photo: "img/Seetaben.jpeg",
    name: "Seetaben Vasava",
    intro: "Community Engagement",
    details: "Seetaben Vasava has been associated with us since 2021 when we launched community learning centers to bridge the learning gaps during Covid. Since then, she has continued her association with Green the Blue Trust (GBT) and currently serves as the library resource person at Chitalda Primary School. In addition to her work in education, Seetaben also collaborates with farmers to promote natural farming practices.",
  },
  {
    photo: "img/shashi.png",
    name: "Shashi Darshan",
    intro: "Strategic Implementation Lead",
    details: "Shashi Darshan, although from an IT background, began his environmental involvement at Tarumitra, Patna. He has trained and inspired hundreds of youths to undertake environmental initiatives at local and national levels. Currently a board member of Green the Blue Charitable Trust, he supports like-minded organizations throughout India with his multidisciplinary skills.",
  },
  {
    photo: "img/manjeet.png",
    name: "Manjeet Chandra",
    intro: "IT & Communications Manager",
    details: "Manjeet is a young volunteer who has been instrumental in designing the digital platform for tree plantation. With an academic background in cultural studies, he has travelled to different cities in India to document the country's diverse cultures. Manjeet has actively participated in various youth volunteer initiatives, focusing on environmental and developmental projects. His efforts have significantly contributed to the success of these initiatives, leveraging his expertise in cultural studies and digital platform development.",
  },
  {
    photo: "img/manohar.jpeg",
    name: "Manohar",
    intro: "Regional Coordinator for Tamil Nadu",
    details: "No Data Available",
  },
  {
    photo: "img/monika.jpeg",
    name: "Monika Gamit",
    intro: "Monitoring",
    details: "Monika Gamit has been actively engaged at the grassroots level, working with children and youth on various projects, including innovative education programs, environment awareness initiatives, and youth animation. As a young farmer, she plays a crucial role in coordinating Green the Blue Trust's (GBT) programs at the grassroots level. Monika is also initiating a mobile library project, aiming to bring books to children in remote villages, thereby fostering a love for reading and learning among the youth in these areas.",
  },
  {
    photo: "img/Vijay.jpeg",
    name: "Vijay D’Souza",
    intro: "Finance & Networking",
    details: "Mr. Vijay D'Souza has over two decades of experience in the development sector. He holds a postgraduate degree from the Entrepreneurship Development Institute of India (EDI) in Ahmedabad. Currently serving as a board member of Green the Blue Trust (GBT), he actively contributes to the organization by offering training programs for youth, children, teachers, and trainers. In addition to his work in training, Mr. D'Souza is also a career counselor, guiding individuals in their career paths.",
  },
];

const Home = () => {
  const navigate = useNavigate();
  const [graphData, setGraphData] = useState({ labels: [], datasets: [] });
  const [totalTrees, setTotalTrees] = useState(0);
  const [graphData2, setGraphData2] = useState({ labels: [], datasets: [] });
  const [totalTrees2, setTotalTrees2] = useState(0);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupText, setPopupText] = useState("");

  const handleLearnMoreClick = (text) => {
    setPopupText(text);
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://greeningthered.greentheblue.org/Backend/get_donations_data.php"
        );
        const data = response.data;

        const monthNames = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];

        const donationsPerMonth = Array(12).fill(0);
        const currentYear = new Date().getFullYear();
        let total = 0;

        data.forEach((item) => {
          const dateParts = item.date.split("/");
          const monthNumber = parseInt(dateParts[0], 10);
          const year = parseInt(dateParts[2], 10);

          if (year === currentYear) {
            const numberOfTrees = parseInt(item.numberOfTrees, 10);
            donationsPerMonth[monthNumber - 1] += numberOfTrees;
            total += numberOfTrees;
          }
        });

        setTotalTrees(total);

        setGraphData({
          labels: monthNames,
          datasets: [
            {
              label: "Number of Trees Donated",
              data: donationsPerMonth,
              borderColor: "#007991",
              backgroundColor: "rgba(75,192,192,0.2)",
              fill: true,
            },
          ],
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const fetchData2 = async () => {
      try {
        const response = await axios.get(
          "https://greeningthered.greentheblue.org/Backend/fetch_tree_data.php"
        );
        const data = response.data;

        const monthNames = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];

        const treesPlantedPerMonth = Array(12).fill(0);
        const currentYear = new Date().getFullYear();
        let total = 0;

        data.forEach((item) => {
          const monthNumber = parseInt(item.month, 10);
          const dateParts = item.date.split("/");
          const year = parseInt(dateParts[0], 10);

          if (year === currentYear) {
            const numberOfTrees = parseInt(item.num_trees, 10);
            treesPlantedPerMonth[monthNumber - 1] += numberOfTrees;
            total += numberOfTrees;
          }
        });

        setTotalTrees2(total);

        setGraphData2({
          labels: monthNames,
          datasets: [
            {
              label: "Number of Trees Planted",
              data: treesPlantedPerMonth,
              borderColor: "#007991",
              backgroundColor: "rgba(75,192,192,0.2)",
              fill: true,
            },
          ],
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    fetchData2();
  }, []);

  const goToDonationPage = (planterType) => {
    navigate(`/donation?planter=${planterType}`);
  };

  const currentYear = new Date().getFullYear();

  return (
    <div className="home">
      <section className="section">
        <h2>About Us</h2>
        <h3 className="cursive-font">Know about this Project</h3>
        <div className="about-content">
          <div className="text-content">
            <p className="section_p">
              "Greening the Red" is an initiative to fight global warming and climate
              change through tree planting. It encourages urban residents to gift
              trees, helping farmers and rural communities restore green cover.
              Schools and colleges are also involved, promoting ecological
              education. The project uses technology to make tree gifting easy and
              affordable. It provides two years of support to ensure the saplings'
              survival, including regular updates and tree care training. By
              fostering collective action and environmental responsibility,
              "Greening the Red" aims to create a healthier, greener planet for
              future generations. Join us in making a sustainable future.
            </p>
            <button onClick={() => navigate("/about")}>Learn More</button>
          </div>
          <div className="video-content">
          <div className="player-wrapper">
            <ReactPlayer
              className="react-player"
              url="video/intro.mp4"
              controls
              light="img/intro_thumbnail.png"
              width="100%"
              height="100%"
            />
          </div>
          </div>
        </div>
      </section>
      <section className="section">
        <h2>Our methodology</h2>
        <div className="tips-container">
          <div className="tip-box">
            <h3>Step 1</h3>
            <p>Donate plant on the website.</p>
          </div>
          <div className="arrow">→</div>
          <div className="tip-box">
            <h3>Step 2</h3>
            <p>Donor Receives 80G Reciept Certificate by Email</p>
          </div>
          <div className="arrow">→</div>
          <div className="tip-box">
            <h3>Step 3</h3>
            <p>
              Tree planting Photos with GPS information is uploaded in the
              website & mailed to the donor
            </p>
          </div>
          <div className="arrow">→</div>
          <div className="tip-box">
            <h3>Step 4</h3>
            <p>
              Handholding farmers/VilIage community members to care for the
              saplings planted for two years
            </p>
          </div>
          <div className="arrow">→</div>
          <div className="tip-box">
            <h3>Step 5</h3>
            <p>
              Every year, external evaluation team monitors and submits reports
              of planting and follow-up activities
            </p>
          </div>
        </div>
      </section>
      <section className="home-new-section">
        <h2>Choose a Planter</h2>
        <div className="service-box-container">
          <div className="service-box">
            <img
              src="img/farmer.png"
              alt="Service 1 Icon"
              className="service-icon"
            />
            <h3>Farmers</h3>
            <p>Help Farmers to get some financial assistance to grow trees.</p>
            <div className="button-group">
              <button
                className="service-button"
                onClick={() =>
                  handleLearnMoreClick(
                    "Small farmers are essential stakeholders in our tree planting mission. With limited landholdings, they can greatly benefit from fruit trees, which provide annual income and improve their health. In our survey, farmers expressed a desire to plant various fruit trees, including mango, jackfruit, coconut, sapota, amla, and jamun. As the backbone of our economy, farmers produce the food we eat. Donating fruit trees not only helps the environment but also boosts farmers' income. We will supply high-quality saplings from local nurseries. By partnering with farmers in our tree plantation mission, we ensure the trees are well cared for and thrive."
                  )
                }
              >
                Learn More
              </button>
              <button
                className="service-button"
                onClick={() => goToDonationPage("Farmers")}
              >
                Donate
              </button>
            </div>
          </div>
          <div className="service-box">
            <img
              src="img/village.png"
              alt="Service 2 Icon"
              className="service-icon"
            />
            <h3>Village Community</h3>
            <p>Help village community to get some financial assistance to grow trees.</p>
            <div className="button-group">
              <button
                className="service-button"
                onClick={() =>
                  handleLearnMoreClick(
                    "Green the Blue Trust has been working with schools to spearhead innovative and meaningful environmental education. It is our earnest desire to plant trees with the involvement of children and school management. We plan to promote native trees on school campuses so that children learn the names and uses of local tree species. Tree planting will be accompanied by eco-education sessions to enhance their understanding and appreciation of the environment."
                  )
                }
              >
                Learn More
              </button>
              <button
                className="service-button"
                onClick={() => goToDonationPage("Village Community")}
              >
                Donate
              </button>
            </div>
          </div>
          <div className="service-box">
            <img
              src="img/school.png"
              alt="Service 3 Icon"
              className="service-icon"
            />
            <h3>School</h3>
            <p>Help School to get some financial assistance to grow trees.</p>
            <div className="button-group">
              <button
                className="service-button"
                onClick={() =>
                  handleLearnMoreClick(
                    "GBT aims to partner with youth groups, women's groups, and village panchayats to identify suitable locations for tree planting. This collaborative effort ensures that the trees will be well cared for by the local community. Trees will be planted on common village land, including sacred groves, around water bodies, along roadsides, and in wastelands. By working together, we can create a greener, healthier environment for everyone."
                  )
                }
              >
                Learn More
              </button>
              <button
                className="service-button"
                onClick={() => goToDonationPage("School")}
              >
                Donate
              </button>
            </div>
          </div>
        </div>
        {isPopupOpen && <Popup text={popupText} onClose={handleClosePopup} />}
      </section>
      <section className="section">
        <div className="graph-container">
          <div className="graph-section">
            <h3>Number of Trees Donated in {currentYear}</h3>
            <Line data={graphData} />
            <p>
              Total Trees Donated: <strong>{totalTrees}</strong>
            </p>
          </div>
          <div className="graph-section">
            <h3>Number of Trees Planted in {currentYear}</h3>
            <Line data={graphData2} />
            <p>
              Total Trees Planted: <strong>{totalTrees2}</strong>
            </p>
          </div>
        </div>
      </section>
      <section className="section">
        <h2>Meet Our Team</h2>
        <h3 className="cursive-font">Our Team Members</h3>
        <div className="team-members">
          {teamMembers.map((member, index) => (
            <TeamMemberCard
              key={index}
              photo={member.photo}
              name={member.name}
              intro={member.intro}
              onLearnMoreClick={() => handleLearnMoreClick(member.details)}
            />
          ))}
        </div>
        {isPopupOpen && <Popup text={popupText} onClose={handleClosePopup} />}
      </section>
    </div>
  );
};

export default Home;
