import React, { useEffect, useState } from "react";
import "./css/Farmers.css";

const Farmers = () => {
  const [farmers, setFarmers] = useState([]);
  const [selectedFarmer, setSelectedFarmer] = useState(null);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fullscreenImage, setFullscreenImage] = useState(null);

  useEffect(() => {
    fetch("https://greeningthered.greentheblue.org/Backend/fetch_farmers.php")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => setFarmers(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const fetchImages = async (farmerName) => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://greeningthered.greentheblue.org/Backend/fetch_image.php?name=${encodeURIComponent(
          farmerName
        )}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setImages(data.images);
    } catch (error) {
      console.error("Error fetching images:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleFarmerClick = (farmer) => {
    setSelectedFarmer(farmer);
    fetchImages(farmer.name);
  };

  const handleClosePopup = () => {
    setSelectedFarmer(null);
    setImages([]);
  };

  const handleImageClick = (image) => {
    setFullscreenImage(image);
  };

  const handleCloseFullscreen = () => {
    setFullscreenImage(null);
  };

  return (
    <div className="farmer-container">
      <h1>Farmers List</h1>
      <div className="farmer-list">
        {farmers.map((farmer, index) => (
          <div
            key={index}
            className="farmer-box"
            onClick={() => handleFarmerClick(farmer)}
          >
            <h2>{farmer.name}</h2>
            <p className="farmer-id">Unique id: {farmer.id}</p>
            <p className="farmer-location">Location: {farmer.place}</p>
            <p className="farmer-trees">Planted {farmer.num_trees} Trees</p>
          </div>
        ))}
      </div>
      {selectedFarmer && (
        <div className="popup-overlay" onClick={handleClosePopup}>
          <div className="popup-box" onClick={(e) => e.stopPropagation()}>
            <div className="top-details">
              <h2 className="farmer-name">{selectedFarmer.name}</h2>
              <button className="close-button" onClick={handleClosePopup}>
                <i className="fas fa-times"></i>
              </button>
            </div>
            <p>Location: {selectedFarmer.place}</p>
            <div className="image-container">
              {loading ? (
                <div className="loading-spinner">Loading...</div>
              ) : (
                images.map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={`${selectedFarmer.name} ${index + 1}`}
                    className="farmer-image"
                    onClick={() => handleImageClick(image)}
                  />
                ))
              )}
            </div>
          </div>
        </div>
      )}
      {fullscreenImage && (
        <div className="fullscreen-overlay" onClick={handleCloseFullscreen}>
          <div
            className="fullscreen-image-container"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              className="close-button fullscreen-close-button"
              onClick={handleCloseFullscreen}
            >
             <i className="fas fa-times"></i>
            </button>
            <img
              src={fullscreenImage}
              alt="Fullscreen"
              className="fullscreen-image"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Farmers;
