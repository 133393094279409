import React from 'react';
import './css/Popup.css';

const Popup = ({ text, onClose }) => {
  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <span className="popup-close" onClick={onClose}>&times;</span>
        <p>{text}</p>
      </div>
    </div>
  );
};

export default Popup;