import React from 'react';
import { useNavigate } from 'react-router-dom';
import './css/ThankYou.css';

const ThankYou = () => {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate('/donation');
  };

  return (
    <div className="thank-you-container">
      <div className="thank-you-box">
        <h1 className="thank-you-text animate-fadeIn">Thank You!</h1>
        <p className="thank-you-message">Thank you for donating money for trees. Your contribution helps us make a greener planet.</p>
        <button className="redirect-button" onClick={handleRedirect}>Donate Again</button>
      </div>
    </div>
  );
};

export default ThankYou;
