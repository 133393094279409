// components/withFullscreen.js
import React, { useState } from 'react';
import './css/withFullscreen.css';

const withFullscreen = (WrappedComponent) => {
  return (props) => {
    const [isFullscreen, setIsFullscreen] = useState(false);

    const toggleFullscreen = () => {
      setIsFullscreen(!isFullscreen);
    };

    return (
      <>
        <WrappedComponent {...props} onClick={toggleFullscreen} />
        {isFullscreen && (
          <div className="fullscreen-overlay" onClick={toggleFullscreen}>
            <div className="fullscreen-container">
            <button className="close-button fullscreen-close-button" onClick={toggleFullscreen}>✖</button>
              <img src={props.src} alt={props.alt} className="fullscreen-image" />
              
            </div>
          </div>
        )}
      </>
    );
  };
};

export default withFullscreen;
