import React from "react";
import "./css/About.css";

const About = () => {
  return (
    <div className="about">
      <section className="section">
        <h2>About Us</h2>
        <p>
          Global warming and climate change are the unintended legacies we've
          handed to the present generation. For the survival of both current and
          future generations, mitigation and adaptation are crucial. One
          effective way to mitigate global warming is through the greening of
          our fragile blue planet becoming ‘Red’. But how can we do this more
          effectively and universally?<br></br><br></br>
          Industrialization and modernization have led to widespread
          deforestation and urbanization, resulting in more people living in
          concrete jungles than ever before. The ‘Greening the Red’ initiative
          seeks to inspire urban residents to gift trees, bridging donors with
          stakeholders. This project empowers farmers and rural communities, who
          bear the brunt of climate change, to plant the desired saplings on
          their farms, village lands, or sacred groves. We also encourage
          children in both rural and urban schools, as well as college students,
          to participate in this tree-planting initiative. To make tree gifting
          affordable and accessible for everyone, we have turned to
          technological solutions.<br></br><br></br>
          Another salient feature of this platform is our work does not stop
          with planting; we pledge to give handholding support to the farmers,
          village community and eco-club members to care for the saplings
          planted at least for two years. In schools, planting will be
          accompanied with activity-based ecological education. This will ensure
          the success of the program. <br></br><br></br>
          Today, we face numerous challenges, both individually and
          collectively. Global warming and climate change affect us all. Despite
          the enormity of the problem, the solutions can be surprisingly simple
          and plausible. Planting a tree does not require rocket science; it can
          be done at the click of a button. This is the convenience we offer
          through our portal.<br></br><br></br>
          Join us in ‘Greening the Red’ and contribute to a healthier planet for
          all.<br></br><br></br>
        </p>
      </section>
    </div>
  );
};

export default About;
