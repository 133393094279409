import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlugCircleXmark } from '@fortawesome/free-solid-svg-icons';
import './css/NoInternet.css';

const NoInternet = () => {
  return (
    <div className="no-internet-container">
      <FontAwesomeIcon icon={faPlugCircleXmark} className="no-internet-icon" />
      <h1>No Internet Connection</h1>
      <p>Please check your internet connection and try again.</p>
    </div>
  );
};

export default NoInternet;